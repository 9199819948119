var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-collapse', {
    attrs: {
      "accordion": "",
      "type": 'border'
    }
  }, [_vm.itinerary ? _c('div', [!(_vm.itineraryToRender && _vm.itineraryToRender.length) && (_vm.getLoading || _vm.processValue || _vm.processValue === 0 || !_vm.statusJobDone) ? _c('b-alert', {
    staticClass: "mb-0 p-1",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.searching')) + "... ")]) : !(_vm.itineraryToRender && _vm.itineraryToRender.length) && !(_vm.getLoading || _vm.processValue || _vm.processValue === 0) ? _c('b-alert', {
    staticClass: "mb-0 p-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.notBookingFound')) + " ")]) : _c('div', _vm._l(_vm.itineraryToRender, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "itinerary"
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isRenderArray[index] === undefined ? true : _vm.isRenderArray[index],
        expression: "isRenderArray[index] === undefined ? true : isRenderArray[index]"
      }],
      staticStyle: {
        "margin-bottom": "1px"
      }
    }, [_c('app-collapse-flight', {
      attrs: {
        "item": item,
        "index": index,
        "min-price": _vm.pricesFilter[0],
        "selected-trip": _vm.selectedTripTemp,
        "index-itinerary": _vm.indexItinerary
      },
      on: {
        "update:selectedTrip": function updateSelectedTrip($event) {
          _vm.selectedTripTemp = $event;
        },
        "update:selected-trip": function updateSelectedTrip($event) {
          _vm.selectedTripTemp = $event;
        },
        "clear": function clear($event) {
          return _vm.$emit('clear');
        }
      }
    }, [_c('BookingDetailTable', {
      attrs: {
        "data-html2canvas-ignore": "",
        "flight-search": _vm.flightSearch,
        "trip": item,
        "index": index,
        "index-itinerary": _vm.indexItinerary,
        "selected-trip": _vm.selectedTripTemp
      },
      on: {
        "update:selectedTrip": function updateSelectedTrip($event) {
          _vm.selectedTripTemp = $event;
        },
        "update:selected-trip": function updateSelectedTrip($event) {
          _vm.selectedTripTemp = $event;
        },
        "clear": function clear($event) {
          return _vm.$emit('clear');
        }
      }
    })], 1)], 1)]);
  }), 0)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }